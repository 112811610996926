.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    background-color: #0a421b;
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #0a421b;
    padding: 2rem;
}
  
.column {
    flex: 1;
    min-width: 200px;
    margin: 1rem;
}
  
.title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: white
}
  
.nav {
    display: flex;
    flex-direction: column;
}
  
.link {
    color: whitesmoke;
    text-decoration: none;
    align-self: center;
    margin-bottom: 0.5rem;
    font-size: large;
    transition: text-shadow 0.1s ease-in;
    width: fit-content;
}
  
.link:hover {
    text-decoration: underline;
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
    color: white;
}

.socials {
    flex-direction: row;
    justify-content: center;
    margin-top: 28px;
}

.icon {
    margin-right: 4px;
    margin-left: 4px;
    font-size: 1.5em;
    transition: color 0.3s ease; /* Smooth color transition */
}
  
.facebook:hover {
    color: #1877F2; /* Facebook blue */
}
  
.instagram:hover {
    color: #E1306C; /* Instagram pink */
}
  
.twitter:hover {
    color: #1DA1F2; /* Twitter blue */
}
  
.email:hover {
    color: #EA4335; /* Gmail red */
}

.copyright {
    color: white;
}