* {
    text-align: center;
    box-sizing: border-box;
    font-family: sans-serif;
    /*margin: 0;*/
}

body {
    margin: 0;
}

button {
    width: 150px;
    background-color: #072E13;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: .75rem 1.5rem;
}

button:hover {
    background-color: #364f37;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }